import React, { useState } from 'react';
import './App.css';
import Games from './Games';
import About from './About';
import Contact from './Contact';
import ReactGA from 'react-ga';
ReactGA.initialize('G-BGGQETD2VD'); // Replace 'UA-XXXXXXXXX-X' with your tracking ID.
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  const [showPanel] = useState(false);
 // const [showPanel, setShowPanel] = useState(false);
  const [activeSection, setActiveSection] = useState(''); // New state to track the active section

  // const togglePanel = () => {
  //   setShowPanel(!showPanel);
  // };

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  return (
    <div className="App">
      <div className="hero">
        <nav className="top-links">
          <ul>
            <li><a href="#games" onClick={() => handleSectionChange('games')}>Games</a></li>
            {/* <li><a href="#experiments">Experiments</a></li> */}
            {/* <li><a href="#about">About</a></li> */}
            <li><a href="#about" onClick={() => handleSectionChange('about')}>About</a></li>
            <li><a href="#contact" onClick={() => handleSectionChange('contact')}>Contact</a></li>
            {/* <li><a href="#contact" onClick={() => handleSectionChange('contact')}>Resume</a></li> */}
            {/* <li><a href="#contact">Contact</a></li> */}
          </ul>
        </nav>
        <div className="hero-image">
          {/* Background image */}
        </div>
      </div>

      {showPanel && (
        <div className="overlay-panel">
          {/* Panel content */}
        </div>
      )}

      {/* <button className="panel-toggle-button" onClick={togglePanel}>
        Toggle Panel
      </button> */}

      {/* Render the Games component based on activeSection */}
      {activeSection === 'games' && <Games />}
      {activeSection === 'about' && <About />}
      {activeSection === 'contact' && <Contact />}
    </div>
  );
}

export default App;

import React, { useState } from 'react';
import './Games.css';

function Games() {
  const [selectedGame, setSelectedGame] = useState(null);
  const [showIframe, setShowIframe] = useState(false);

  const games = [
    { id: 1, title: 'Sugar Factory', fileName: '1.jpg', url: 'https://sugarpusher.onrender.com/' },
    { id: 2, title: 'Bit Fighters', fileName: '2.jpg', url: 'https://bitfighters.club/' },
    { id: 3, title: 'Kamakura Defense', fileName: '3.jpg', url: 'https://play-njvp.onrender.com' },
    { id: 4, title: 'Bot Breeders', fileName: '4.jpg', url: 'https://feedandgrow-web-server.onrender.com' },
    { id: 5, title: 'Team Arena Tactics', fileName: '5.jpg', url: 'https://teamarenaonline.onrender.com' },
    { id: 6, title: 'Bad Buddies', fileName: '6.jpg', url: 'https://bitbuddies.netlify.app/' },
    { id: 7, title: 'Bubby Berry Fortress', fileName: '7.jpg', url: 'https://bbf-kn8o.onrender.com' },
    { id: 8, title: 'Feed Fresh Fruit', fileName: '8.jpg', url: 'https://feed-fresh-fruit.onrender.com/' },
  ];

  const handleThumbnailClick = (game) => {
    setSelectedGame(game.url); // Use the URL field to open the specific game
    setShowIframe(true); // Open the iframe overlay
    // if (game.title === 'Sugar Factory') {
    //   setSelectedGame(game.url); // Use the URL field to open the specific game
    //   setShowIframe(true); // Open the iframe overlay
    // } else if(game.title === 'Bit Fighters') {
    //   setSelectedGame(game.url); // Use the URL field to open the specific game
    //   setShowIframe(true); // Open the iframe overlay

    // }else {
    //   console.log('Other games can have different behaviors or links here.');
    // }
  };

  const closeIframe = () => {
    setShowIframe(false); // Close the iframe overlay
    setSelectedGame(null); // Clear the selected game
  };

  return (
    <div>
      <div className="gallery">
        {games.map(game => (
          <div key={game.id} className="game-item">
            <span className="game-title">{game.title}</span>
            <img
              src={`/assets/thumbs/${game.fileName}`}
              alt={game.title}
              onClick={() => handleThumbnailClick(game)}
            />
          </div>
        ))}
      </div>
      {showIframe && (
        <div className="iframe-overlay">
          <iframe
            title="Game Frame"
            src={selectedGame}
            width="100%"
            height="100%"
            frameBorder="0"
          ></iframe>
          <button className="close-iframe" onClick={closeIframe}>Close</button>
        </div>
      )}
    </div>
  );
}
export default Games;

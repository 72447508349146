import React from 'react';
import './style.css';
function About() {
  return (
    <div>
      <h1>About</h1>
      <div className="contactLayout">
        <div className="contact-info">
          <p><strong>Hello!</strong></p>
          <p>My name is Zach. I am an accomplished project manager and Disciplined Agile scrum master, with a love for software development!
            Especially multiplayer games and game design!
          </p>
          <p>
            This website hosts some of my own creations which I have crafted with pure joy.
          </p>
          <p>
            When I am not busy in the office or studio, working with teams, coding, or painting, I love to be in the garden with the plants and insects or in the forest on a hike.
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;

import React from 'react';
import './style.css';
function Contact() {
  return (
    <div>
      <h1>Contact Page</h1>
      <div className="contactLayout">
      <div className="contact-info">
      <p><strong>Contact info:</strong></p>
        <p><strong>Name:</strong> Zach Reed</p>
        <p><strong>Phone:</strong> 419.410.8015</p>
        <p><strong>Email:</strong> <a href="mailto:zreedPMP@pm.me">zreedPMP@pm.me</a></p>
      </div>
      </div>
    </div>
  );
}

export default Contact;
